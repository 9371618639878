import * as React from "react"

// markup
const NotFoundPage = () => {
    return (
        <main>
            404 not found
        </main>
    )
};

export default NotFoundPage
